.calendar-page {
  padding-top: 30px;
  min-height: 800px;
}

.calendar-page > h3 {
  padding-left: 30px;
  padding-bottom: 30px;
}

.calendar-error-massage {
  width: 100%;
  text-align: center;
  font-size: 24px;
  /* margin-bottom: 50px; */
}

.calendar-container {
  padding: 50px 30px;
  display: flex;
  gap: 15px;
  justify-content: space-between;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
}

.left-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.left-container  > .left {
  font-size: 18px;
  width: 33%;
}

.calendar-container  > .right {
  width: 60%;
  min-width: 700px;
  max-width: 950px;
  padding-right: 15px;

  @media (max-width: 1400px) {
    width: 40%;
    min-width: 40%;
    max-width: 100%;
    padding-right: 0;
  }

  @media (max-width: 1300px) {
    width: 400px;
    min-width: 30%;
    max-width: 50%;
  }

  @media (max-width: 900px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 700px;
    max-width: 950px;
  }

  @media (max-width: 750px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 500px;
    max-width: 950px;
  }

  @media (max-width: 560px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    max-width: 500px;
  }
}

.calendar-inner-container {
  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 900px) {
    display: flex;
    gap: 50px;
    flex-direction: row;
  }

  @media (max-width: 560px) {
    display: flex;
    gap: 50px;
    flex-direction: column;
  }
}

.calendar-container  > .right.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-panel {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* The container */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-top: 2px;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkbox-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: rgb(224, 224, 224);
    border: transparent 1px solid;
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkbox-checkmark {
    background-color: rgb(224, 224, 224);
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkbox-checkmark {
    background-color: white;
    border: #2acf2a 1px solid;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkbox-checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .checkbox-checkmark:after {
    left: 6px;
    top: 2px;
    width: 10px;
    height: 15px;
    border: solid #2acf2a;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }


  /* Calendar Blocks */

  .rs-calendar-panel .rs-calendar-table-cell-day{
    display: none;
  }
  .rs-calendar-table-cell-content {
    padding: 1px 0;
  }

  .calendar-cell {
    height: 100%;
    display: flex;
    font-size: 22px;
    align-items: center;
    justify-content: center;
  }
  
  .rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    box-shadow: none;
  }
/* 
  .rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content .calendar-cell {
    background-color: #60e4ad;
  }

  .rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content .calendar-cell.non-available-cb {
    background-color: rgb(221, 221, 221);
  } */

  .non-available-cb {
    background-color: rgb(221, 221, 221);
    color: gray;
  }

  .another-month-cb {
    background-color: rgb(243, 243, 243);
    color: rgb(206, 206, 206);
  }

  .available-cb {
    background-color: #25a972;
    color: white;
  }

  .cb-today {
    color: rgb(44, 190, 200);
    font-weight: 900;
  }

  /* Modal */
  .rs-modal-content {
    
    margin-top: auto;
  }
  .modal-subtitle {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .modal-available-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .modal-subtitle-text {
    margin-top: -4.5px;
    color: rgb(7, 73, 115);
  }

  button.modal-avail-btn {
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    max-height: 50px;
    border: rgb(159, 159, 159) 1px solid;
  }

  .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: rgb(7, 73, 115);
    font-family: 'Carrois' !important;
    font-weight:900;
  }

  .rs-btn.rs-btn-primary {
    background-color: rgb(7, 73, 115);
  }

  .form-error {
    color: red;
    font-size: 11px;
  }

  .calendar-hidden-btns .rs-calendar-header .rs-calendar-header-month-toolbar .rs-btn-icon-placement-left{
    display: none;
  }

  .calendar-hidden-btns .rs-calendar-header .rs-calendar-header-month-toolbar .rs-calendar-header-title{
    color: #8e8e93;
    cursor: auto;
  }

  .calendar-hidden-today .rs-calendar-header .rs-calendar-btn-today,
  .calendar-hidden-btns .rs-calendar-header .rs-calendar-btn-today {
    display: none;
  }

  .rs-modal-body {
    margin-top: 0 !important;
  }

  /* @keyframes fadeInOut {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  } */
  
  .success-popup{
    /* position:fixed;
    z-index: 1000;
    left: 50%;
    top: 9%;
    -ms-transform: translate(-50%,-50%);
    -moz-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
     transform: translate(-50%,-50%);
     animation: fadeIn 2s;
      animation-iteration-count: 1;
      opacity: 1; */
      margin-top: calc(50vh - 100px);
  }
